import { Box, Container, Grid, Link, Stack, Typography, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useLogin, useUserSiteLogin, useResetPassword } from 'api/auth';
import { useForm, FormProvider } from 'react-hook-form';
import cookie from 'js-cookie';
import { useNavigate } from 'react-router';
import { TextField } from 'component/base/TextField';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'component/hooks/useSnackbar';
import { setAuthorizationHeaders } from 'api/axiosService';
import { useQueryParams } from 'component/hooks/queryParams';
import { ProgressiveButton } from 'component/base/ProgressiveButton';

const PREFIX = 'Login';

const classes = {
  container: `${PREFIX}Container`,
};

const StyledContainer = styled(Container)({
  [`&.${classes.container}`]: {
    display: 'flex',
    minHeight: '100vh',
    paddingBottom: '1.50rem',
    paddingTop: '1.50rem',
    '& > *': {
      marginBottom: 'auto',
      marginTop: 'auto',
      width: '100%',
    },
  },
});

interface FormValue {
  username: string;
  password: string;
  siteId: string;
}

export default function Login({ isWpSiteControl }: { readonly isWpSiteControl: boolean }) {
  const params = useQueryParams();
  const email = params.get('email') ?? '';
  const retUrl = params.get('retUrl') ?? '';
  const methods = useForm<FormValue>({
    defaultValues: {
      username: email,
      password: '',
    },
  });

  const { handleSubmit, getValues } = methods;
  const navigate = useNavigate();
  const login = useLogin();
  const userLogin = useUserSiteLogin();
  const resetPassword = useResetPassword();
  const loginToUse = isWpSiteControl ? userLogin : login;
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async (data: FormValue) => {
    const result = await loginToUse.mutateAsync({
      username: data.username,
      password: data.password,
    });

    setAuthorizationHeaders(result.data.token);

    cookie.set('user_session', result.data.token, {
      expires: 3560,
      sameSite: 'strict',
    });

    if (retUrl) {
      navigate(retUrl);
    } else {
      if (isWpSiteControl) {
        navigate('/sites');
      } else {
        navigate('/dashboard');
      }
    }
  };

  return (
    <StyledContainer maxWidth="xs" className={classes.container}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack alignContent="center" alignItems="center" spacing={3.5}>
            <Box alignItems="center">
              <Typography textAlign="center" variant="h2">
                {t('login.title')}
              </Typography>
              <Typography textAlign="center" variant="body2" color="grayText">
                {t('login.description')}
              </Typography>
            </Box>
            <Divider sx={{ width: '100%' }} />
            <Stack spacing={3} sx={{ width: '85%' }}>
              <TextField
                defaultValue=""
                name="username"
                disabled={login.isPending}
                fullWidth
                label="User Name"
                rules={{ required: true }}
                sx={{ backgroundColor: 'white' }}
              />
              <TextField
                name="password"
                disabled={login.isPending}
                fullWidth
                label={
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <label htmlFor="password-input">{t('password')}</label>
                    </Grid>
                    <Grid item>
                      <Link
                        sx={{ textDecoration: 'none' }}
                        onClick={() => {
                          const { username } = getValues();

                          if (!username) {
                            enqueueSnackbar(t('login.missingUsernameWarning'), {
                              key: 'missingUsernameWarning',
                              variant: 'error',
                            });

                            return;
                          }

                          resetPassword.mutateAsync({ email: username });

                          enqueueSnackbar(t('login.passwordResetSent', { email: username }), {
                            key: 'passwordResetSent',
                            variant: 'success',
                          });
                        }}
                      >
                        {t('forgot_password')}
                      </Link>
                    </Grid>
                  </Grid>
                }
                type="password"
                rules={{ required: true }}
                sx={{ backgroundColor: 'white' }}
              />
            </Stack>
            <ProgressiveButton
              color="primary"
              variant="contained"
              isLoading={login.isPending}
              onClick={() => {
                handleSubmit(onSubmit)();
              }}
              sx={{ paddingLeft: '40px', paddingRight: '40px' }}
              text={t('login.button')}
            />
          </Stack>
        </form>
      </FormProvider>
    </StyledContainer>
  );
}
